import { h } from 'preact';
// eslint-disable-next-line react/no-deprecated
import { render } from 'react';

import ConversionStep from '../auto/components/ConversionStep';
import ConversionFormNoResultApp from '../auto/components/ConversionFormNoResult/ConversionFormApp';
import DetailTabsConsortium from '../auto/components/Consortium/DetailTabs';
import ConsortiumConversionFormApp from '../auto/components/ConsortiumConversionFormApp';
import ListAppAccessories from '../auto/components/Accessories/ListApp';
import ListAppParts from '../auto/components/Parts/ListApp';
import SimpleFilterDirectSalesCategories from '../auto/components/DirectSalesCategories/SimpleFilter';
import ListDirectSalesCategories from '../auto/components/DirectSalesCategories/ListApp';
import SimpleFilterDirectSales from '../auto/components/DirectSales/SimpleFilter';
import ListAppDirectSales from '../auto/components/DirectSales/ListApp';
import DirectSalesFormModal from '../auto/components/DirectSalesFormModal';
import NewOffersList from '../auto/components/Listings/Offers/News/Psa';
import NewsPsaCard from '../auto/components/Cards/NewsPsa';
import NewVehiclesList from '../auto/components/Listings/NewVehicles/Psa';
import PartConversionFormApp from '../auto/components/PartConversionFormApp';
import PillsFilter from '../auto/components/PillsFilter';
import PsaCard from '../auto/components/Cards/Psa';
import ResultSearchSimpleApp from '../auto/components/SearchFilter/ResultSearchSimpleApp';
import ScheduleServiceConversionFormApp from '../auto/components/ScheduleServiceConversionFormApp';
import ScheduleServiceFormModal from '../auto/components/ScheduleServiceFormModal';
import ScheduleServiceFixedTopFormModal from '../auto/components/ScheduleServiceFixedTopFormModal';
import SearchFilterPart from '../auto/components/Parts/SimpleFilter';
import SearchFilterUsedmodel from '../auto/components/SearchFilterUsedmodel';
import SearchFilterAccessory from '../auto/components/Accessories/SimpleFilter';
import SimpleFilterButtonsAccessory from '../auto/components/Accessories/SimpleFilterButtons';
import SimpleFilterNewVehicle from '../auto/components/NewVehicle/SimpleFilter';
import SimpleFilterOffers from '../auto/components/Offers/SimpleFilter';
import SimpleFilterButtonsApp from '../auto/components/SearchFilter/SimpleFilterButtonsApp';
import SimpleFilterButtonsPart from '../auto/components/Parts/SimpleFilterButtons';
import SimpleFilterButtonsUsedModel from '../auto/components/SearchFilterUsedmodel/SimpleFilterButtons';
import SimpleFilterConsortia from '../auto/components/Consortia/SimpleFilter';
import ListAppConsortia from '../auto/components/Consortia/ListApp';
import PlanMonthsChooser from '../auto/components/Consortium/PlanMonthsChooser';
import StaticConversionFormApp from '../auto/components/StaticConversionFormApp';
import UsedVehicleConversionFormApp from '../auto/components/UsedVehicleConversionFormApp';
import UsedVehiclesList from '../auto/components/Listings/UsedVehicles/Psa';
import WhatsAppBoxWithModal from '../auto/components/WhatsAppBoxWithModal';
import WhatsAppFormModal from '../auto/components/WhatsAppFormModal';
import ShowcaseChoiceVersion from '../auto/components/ShowcaseChoiceVersion/ShowcaseChoiceVersion';
import NewVehicleConversionFormApp from '../auto/components/NewVehicleConversionFormApp';
import DetailTabs from '../auto/components/DetailTabs';
import AccordionVehicle from '../auto/components/AccordionVehicle';
import ChronometerBox from '../auto/components/ChronometerBox';
import TimeMissingCard from '../auto/components/TimeMissingCard';
import VideoGallery from '../auto/components/VideoGallery/VideoGallery';
import VersionComparison from '../auto/components/VersionComparison/VersionComparison';
import OfferDDMListApp from '../auto/components/Listings/OffersDDMV2/ListApp';
import OfferDDMFilter from '../auto/components/Listings/OffersDDMV2/Filter/SimpleFilterV2';
import OfferDDMResultSearchSimple from '../auto/components/Listings/OffersDDM/Filter/Result/ResultSearchSimple';
import OfferDDMConversionFormNoResult from '../auto/components/Listings/OffersDDM/Filter/Result/ConversionFormNoResult';
import CustomFormApp from '../auto/components/Forms/CustomForm/CustomFormApp';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  require('preact/debug');
}

(() => {
  window.h = h;
  window.render = render;

  /**
   * Para ser usado quando se deseja sobrescrever conteúdo do container de rendeziração.
   * Seu uso é semelhante a função render
   */
  window.hydrate = (vnode, parent) =>
    render(vnode, parent, parent.firstElementChild);

  // Adicione aqui chaves:valor com os componentes que serão atribuídos ao escopo
  // global. Não passe eles pelo hyperscript (h) aqui pois a página que os usa
  // é quem deve compilá-lo com os pros.
  const components = {
    ConversionStep,
    ConversionFormNoResultApp,
    DetailTabsConsortium,
    ConsortiumConversionFormApp,
    DirectSalesFormModal,
    ListAppAccessories,
    ListAppParts,
    SimpleFilterDirectSalesCategories,
    ListDirectSalesCategories,
    SimpleFilterDirectSales,
    ListAppDirectSales,
    NewOffersList,
    NewsPsaCard,
    NewVehiclesList,
    ScheduleServiceConversionFormApp,
    ScheduleServiceFormModal,
    ScheduleServiceFixedTopFormModal,
    PartConversionFormApp,
    PillsFilter,
    PsaCard,
    ResultSearchSimpleApp,
    SearchFilterAccessory,
    SearchFilterPart,
    SearchFilterUsedmodel,
    SimpleFilterNewVehicle,
    SimpleFilterOffers,
    SimpleFilterButtonsAccessory,
    SimpleFilterButtonsApp,
    SimpleFilterButtonsPart,
    SimpleFilterButtonsUsedModel,
    SimpleFilterConsortia,
    ListAppConsortia,
    PlanMonthsChooser,
    StaticConversionFormApp,
    UsedVehiclesCard: PsaCard,
    UsedVehicleConversionFormApp,
    UsedVehiclesList,
    WhatsAppBoxWithModal,
    WhatsAppFormModal,
    ShowcaseChoiceVersion,
    NewVehicleConversionFormApp,
    DetailTabs,
    TimeMissingCard,
    AccordionVehicle,
    ChronometerBox,
    VideoGallery,
    VersionComparison,
    OfferDDMListApp,
    OfferDDMFilter,
    OfferDDMResultSearchSimple,
    OfferDDMConversionFormNoResult,
    CustomFormApp,
  };

  Object.keys(components).forEach(c => {
    window[c] = components[c];
  });
})();
